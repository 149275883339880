import React from 'react';
import './fallbackstyle.css'
function FallbackComponent() {
    return (
        <>
            <div className="loader"></div>
        </>
    );
}

export default FallbackComponent;